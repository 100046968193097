/*import React from 'react';

function GridViewer({ photos, onPhotoClick }) {
  return (
    <div className="grid-viewer">
      {photos.map((photo, index) => (
        <div className="grid-cell" key={index}>
        <img key={index} src={photo} alt={`Photo ${index + 1}`} onClick={() => onPhotoClick(index)} />
        </div>
      ))}
    </div>
  );
}

export default GridViewer; */

import React, { useEffect } from 'react';
import './App.css';

function GridViewer({ photos, onPhotoClick }) {
    useEffect(() => {
        const images = document.querySelectorAll('.grid-viewer img');
        images.forEach(img => {
            const randomDelay = Math.random() * 1; // Random delay between 0 and 2 seconds
            img.style.animationDelay = `${randomDelay}s`;
            img.classList.add('fade-in');
        });
    }, [photos]);

    return (
        <div className="grid-viewer">
            {photos.map((photo, index) => (
                <img
                    key={index}
                    src={photo}
                    alt={`Photo ${index + 1}`}
                    onClick={() => onPhotoClick(index)}
                    className="grid-photo"
                />
            ))}
        </div>
    );
}

export default GridViewer;
