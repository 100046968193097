import React from 'react';

function PhotoViewer({ photo, onPhotoClick, onNumberClick, pageNumber, onQuestionMarkClick }) {
    return (
        <div className="photo-viewer" oncontextmenu="return false;" onClick={onPhotoClick}>
            <img src={photo} alt="Display" oncontextmenu="return false;" style={{ width: '100%', height: 'auto' }} />
            <h1
                className="page-number"
                onClick={e => {
                    e.stopPropagation();  // Prevents the photo click event from firing when clicking on the heading
                    onNumberClick();
                }}
            >
                gallery
            </h1>
            
            <h1
                className="info-link"
                onClick={e => {
                    e.stopPropagation();  // Prevents the photo click event from firing when clicking on the heading
                    onQuestionMarkClick();
                }}
            >
                info
            </h1>
        </div>
    );
}

export default PhotoViewer;
